<template>
  <div class="card ">
    <div v-if="loading" class="card-body">
      <div class="text-center">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
    </div>
    <template v-else>
      <div class="card-body p-sm-3">
        <b-alert
          :variant="msg.type"
          dismissible
          class="mt-3"
          v-model="msg.has"
          :show="msg.text"
          >{{ msg.text }}</b-alert
        >

        <form @submit.prevent="handleSubmit" novalidate>
          <!-- email -->
          <div class="form-group mb-2">
            <label for="email" class="required">{{ $t("form.email") }}</label>
            <input
              class="form-control"
              v-model="form.email"
              type="email"
              id="email"
              :readonly="type === 'view'"
              :class="[
                {
                  'is-invalid': submitted && $v.form.email.$error,
                },
                type === 'view' ? 'form-control-plaintext' : 'form-control',
              ]"
              :placeholder="$t('form.email-placeholder')"
            />
            <div
              v-if="submitted && $v.form.email.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.form.email.required">{{
                $t("form.email-req")
              }}</span>
              <span v-if="!$v.form.email.email">{{
                $t("form.email-valid")
              }}</span>
            </div>
          </div>

          <!-- login -->
          <div class="form-group mb-2">
            <label for="login" class="required">{{ $t("form.login") }}</label>
            <input
              class="form-control"
              v-model="form.name"
              id="login"
              :placeholder="$t('form.login-placeholder')"
              :readonly="type === 'view'"
              :class="[
                {
                  'is-invalid': submitted && $v.form.name.$error,
                },
                type === 'view' ? 'form-control-plaintext' : 'form-control',
              ]"
            />
            <div
              v-if="submitted && $v.form.name.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.form.name.required">{{
                $t("form.login-req")
              }}</span>
            </div>
          </div>

          <!-- password -->
          <div v-if="type !== 'view'" class="form-group mb-2">
            <label for="password" class="required">{{
              $t("form.password")
            }}</label>
            <div class="input-group input-group-merge">
              <input
                v-model="form.password"
                :type="showPassword ? 'text' : 'password'"
                id="password"
                class="form-control"
                :placeholder="$t('form.password-placeholder')"
                :class="{
                  'is-invalid': submitted && $v.form.password.$error,
                }"
              />
              <div
                class="input-group-append"
                @click="showPassword = !showPassword"
                role="button"
              >
                <div class="input-group-text">
                  <span v-if="showPassword" class="fe-eye"></span>
                  <span v-else class="fe-eye-off"></span>
                </div>
              </div>
              <div
                v-if="submitted && $v.form.password.$error"
                class="invalid-feedback"
              >
                <div v-if="!$v.form.password.required">
                  {{ $t("form.password-req") }}
                </div>
                <div v-if="!$v.form.password.minLength">
                  {{ $t("form.password-min") }} 8
                </div>
                <div v-if="!$v.form.password.maxLength">
                  {{ $t("form.password-max") }} 16
                </div>
                <!-- <div v-if="!$v.form.password.valid">Пароль не валидный, должен содержать: A-Z, a-z, 0-9, #?!@$%^&*-</div> -->
              </div>
            </div>
          </div>

          <!-- repassword -->
          <div v-if="type !== 'view'" class="form-group mb-2">
            <label for="repassword" class="required">{{
              $t("form.password-confirm")
            }}</label>
            <div class="input-group input-group-merge">
              <input
                v-model="form.repassword"
                :type="showRepassword ? 'text' : 'password'"
                id="repassword"
                class="form-control"
                :placeholder="$t('form.password-confirm-placeholder')"
                :class="{
                  'is-invalid': submitted && $v.form.repassword.$error,
                }"
              />
              <div
                class="input-group-append"
                @click="showRepassword = !showRepassword"
                role="button"
              >
                <div class="input-group-text">
                  <span v-if="showRepassword" class="fe-eye"></span>
                  <span v-else class="fe-eye-off"></span>
                </div>
              </div>
              <div
                v-if="submitted && $v.form.repassword.$error"
                class="invalid-feedback"
              >
                <div v-if="!$v.form.repassword.required">
                  {{ $t("form.password-confirm-req") }}
                </div>
                <div v-if="!$v.form.repassword.sameAsPassword">
                  {{ $t("form.password-confirm-not-match") }}
                </div>
              </div>
            </div>
          </div>

          <!-- roles -->
          <div class="form-group mb-2">
            <label for="role" class="required">{{ $t("form.role") }}</label>
            <div v-if="type === 'view'" class="input-group input-group-merge">
              {{ rolesToStr(form.roles) }}
            </div>
            <div v-else class="input-group input-group-merge">
              <multiselect
                v-model="form.roles"
                :options="roles"
                track-by="id"
                label="name"
                :searchable="false"
                multiple
                :placeholder="$t('form.role-select')"
                :class="{
                  'is-invalid': submitted && $v.form.roles.$error,
                }"
              />
              <div
                v-if="submitted && $v.form.roles.$error"
                class="invalid-feedback"
              >
                <div v-if="!$v.form.roles.required">
                  {{ $t("form.role-req") }}
                </div>
              </div>
            </div>
          </div>

          <!-- status -->
          <div
            v-if="type === 'view' || type === 'edit'"
            class="form-group mb-2"
          >
            <label for="status" class="required">{{ $t("form.status") }}</label>
            <div v-if="type === 'view'" class="input-group input-group-merge">
              <span class="badge" :class="classByStatus(form.status)">{{
                form.status.title
              }}</span>
            </div>
            <div v-else class="input-group input-group-merge">
              <multiselect
                v-model="form.status"
                :options="statuses"
                track-by="id"
                label="title"
                :multiple="false"
                :searchable="false"
                :placeholder="$t('form.status-select')"
                :class="{
                  'is-invalid': submitted && $v.form.status.$error,
                }"
              />
              <div
                v-if="submitted && $v.form.status.$error"
                class="invalid-feedback"
              >
                <div v-if="!$v.form.status.required">
                  {{ $t("form.status-req") }}
                </div>
              </div>
            </div>
          </div>

          <!-- company -->
          <div class="form-group mb-2">
            <label for="company" class="required">{{
              $t("form.company")
            }}</label>
            <div v-if="type === 'view'" class="input-group input-group-merge">
              {{ form.company.name }}
            </div>
            <div v-else class="input-group input-group-merge">
              <multiselect
                v-model="form.company"
                :options="companies"
                track-by="id"
                label="name"
                :multiple="false"
                :placeholder="$t('form.company-select')"
                :class="{
                  'is-invalid': submitted && $v.form.company.$error,
                }"
              />
              <div
                v-if="submitted && $v.form.company.$error"
                class="invalid-feedback"
              >
                <div v-if="!$v.form.company.required">
                  {{ $t("form.company-req") }}
                </div>
              </div>
            </div>
          </div>

          <!-- city -->
          <div class="form-group mb-2">
            <label for="city">{{ $t("form.city") }}</label>
            <div v-if="type === 'view'" class="input-group input-group-merge">
              <template v-if="form.city && form.city.name">{{
                form.city.name
              }}</template>
            </div>
            <div v-else class="input-group input-group-merge">
              <multiselect
                v-model="form.city"
                :options="cities"
                track-by="id"
                label="name"
                :multiple="false"
                :placeholder="$t('form.city-select')"
              />
            </div>
          </div>

          <!-- CREATE button -->
          <div v-if="type === 'create'" class="mt-4 text-center text-lg-right">
            <div class="form-group mb-0">
              <router-link to="/users" class="btn btn-warning mx-2 mb-2">
                {{ $t("btn.cancel") }}
              </router-link>
              <button
                :disabled="inprogress"
                class="btn btn-primary mx-2 mb-2 mr-lg-0"
                type="submit"
              >
                {{ $t("user.add") }}
              </button>
            </div>
          </div>

          <!-- EDIT button -->
          <div v-if="type === 'edit'" class="mt-4 text-center text-lg-right">
            <div class="form-group mb-0">
              <router-link to="/users" class="btn btn-warning mx-2 mb-2">
                {{ $t("btn.cancel") }}
              </router-link>
              <button
                :disabled="inprogress"
                class="btn btn-primary mx-2 mb-2 mr-lg-0"
                type="submit"
              >
                {{ $t("btn.save") }}
              </button>
            </div>
          </div>

          <!-- VIEW button -->
          <div v-if="type === 'view'" class="mt-4 text-center text-lg-right">
            <div class="form-group mb-0">
              <router-link to="/users" class="btn btn-warning mx-2 mb-2">
                {{ $t("btn.cancel") }}
              </router-link>
              <router-link
                :to="`/users/edit/${$route.params.id}`"
                class="btn btn-primary mx-2 mb-2 mr-lg-0"
                >{{ $t("btn.edit") }}</router-link
              >
            </div>
          </div>
        </form>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import Multiselect from "vue-multiselect";
import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";

export default {
  components: {
    Multiselect,
    DatePicker,
  },
  props: {
    type: {
      type: String, // create, edit, view
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      submitted: false,
      inprogress: false,
      msg: {
        has: false,
        type: "",
        text: "",
      },
      form: {
        email: "",
        name: "",
        password: "",
        repassword: "",
        roles: "",
        status: "",
        company: "",
        city: "",

        is_blocked: "",
        is_active: "",
      },
      formInit: null,
      showPassword: false,
      showRepassword: false,
      defaultRole: "",
      defaultCompany: "",
      defaultStatus: "",
    };
  },
  validations() {
    return {
      form: this.formRules,
    };
  },
  created() {
    this.loadData();

    this.defaultRole = this.roles[1]; // "a"
    this.form.roles = this.defaultRole;

    this.defaultCompany = this.companies[0]; // first company
    this.form.company = this.defaultCompany;

    this.defaultStatus = this.statuses[1]; // "Не активен"
    this.form.status = this.defaultStatus;
  },
  computed: {
    ...mapGetters(["roles", "cities", "companies", "statuses"]),

    formRules() {
      let rules = {};

      rules.email = {
        required,
        email,
      };

      rules.name = {
        required,
      };

      rules.password = {
        required,
        // valid: function(value) {
        //   const containsUppercase = /[A-Z]/.test(value)
        //   const containsLowercase = /[a-z]/.test(value)
        //   const containsNumber = /[0-9]/.test(value)
        //   const containsSpecial = /[#?!@$%^&*-]/.test(value)
        //   return containsUppercase && containsLowercase && containsNumber && containsSpecial
        // },
        minLength: minLength(8),
        maxLength: maxLength(16),
      };

      rules.repassword = {
        required,
        sameAsPassword: sameAs("password"),
      };

      rules.roles = {
        required,
      };

      if (this.type === "edit") {
        rules.status = {
          required,
        };
      }

      rules.company = {
        required,
      };

      return rules;
    },
  },
  methods: {
    async loadData() {
      try {
        await Promise.all([
          this.$store.dispatch("fetchRoles"),
          this.$store.dispatch("fetchCities"),
          this.$store.dispatch("fetchCompanies"),
          this.$store.dispatch("fetchStatuses"),
        ]);

        if (this.type === "edit" || this.type === "view") {
          await this.getUser();
        }

        this.loading = false;
      } catch (error) {
        console.log("loadData Error: ", error);
      }
    },
    async getUser() {
      this.loading = true;

      await axios
        .get(this.$urls.URL_USERS + "/" + this.$route.params.id)
        .then((response) => {
          console.log(
            `response, user id (${this.$route.params.id}): `,
            response
          );

          this.fillInitData(response);
          this.form = { ...this.formInit };
          this.loading = false;
        })
        .catch((error) => {
          console.log("getUser Error", error);
        });
    },
    fillInitData(response) {
      // ["0", "1", "2"]; // Заблокирован, Не активен, Активен
      // is_active[1/0], is_blocked[1/0]
      const statusId =
        response.data.is_blocked === "1"
          ? "0"
          : response.data.is_active === "1"
          ? "2"
          : "1";

      this.formInit = {
        email: response.data.email,
        name: response.data.name,
        password: "",
        repassword: "",
        roles: response.data.roles,
        is_blocked: response.data.is_blocked,
        is_active: response.data.is_active,
        status: this.statuses.find((s) => s.id === statusId),
        company: this.companies.find((c) => c.id === response.data.company_id),
        city: this.cities.find((c) => c.id === response.data.city_id),
      };
    },
    async handleSubmit() {
      this.submitted = true;
      this.msg = {
        has: false,
        type: "",
        text: "",
      };

      this.$v.$touch();

      if (!this.$v.form.$invalid) {
        this.inprogress = true;

        const formData = new FormData();

        // form
        formData.append("email", this.form.email);
        formData.append("name", this.form.name);
        formData.append("password", this.form.password);
        formData.append("password_confirmation", this.form.repassword);

        this.form.roles.map((r) => {
          formData.append("roles[]", r.id);
        });

        if (this.type === "edit") {
          formData.append("status", this.form.status.id);
        }

        formData.append("company_id", this.form.company.id);
        formData.append("city_id", this.form.city.id || "");

        // Display the key/value pairs ###debug
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + " :", pair[1]);
        // }

        if (this.type === "create") {
          try {
            const response = await axios.post(
              this.$urls.URL_USERS + "/add",
              formData,
              {
                headers: {
                  "content-type": "multipart/form-data",
                },
              }
            );

            this.msg.has = true;
            this.msg.type = "success";
            this.msg.text = this.$t("user.msg-add-success");

            this.inprogress = false;
          } catch (error) {
            console.log("Error: ", error);
            this.msg.has = true;
            this.msg.type = "danger";
            this.msg.text =
              error.response?.data?.message || this.$t("user.msg-add-error");

            this.inprogress = false;

            if (error.response.data.errors) {
              for (const [key, value] of Object.entries(
                error.response.data.errors
              )) {
                this.msg.text += `[${key}: ${value}] `;
              }
            }
          }
        }

        if (this.type === "edit") {
          try {
            const response = await axios.post(
              this.$urls.URL_USERS + "/edit/" + this.$route.params.id,
              formData,
              {
                headers: {
                  "content-type": "multipart/form-data",
                },
              }
            );

            this.msg.has = true;
            this.msg.type = "success";
            this.msg.text = this.$t("user.msg-edit-success");

            this.inprogress = false;
          } catch (error) {
            console.log("User edit, error: ", error);

            this.msg.has = true;
            this.msg.type = "danger";
            this.msg.text =
              error.response?.data?.message || this.$t("user.msg-edit-error");

            this.inprogress = false;

            if (error.response.data.errors) {
              for (const [key, value] of Object.entries(
                error.response.data.errors
              )) {
                this.msg.text += `[${key}: ${value}] `;
              }
            }
          }
        }
      }
    },
    classByStatus(status) {
      // ["0", "1", "2"]; // Заблокирован, Не активен, Активен

      let c = "badge-danger";

      if (status.id === "1") {
        c = "badge-warning";
      }

      if (status.id === "2") {
        c = "badge-success";
      }

      return c;
    },
    rolesToStr(roles) {
      const rolesNames = roles?.map((r) => `${r.name} [${r.id}]`);

      return rolesNames?.length ? rolesNames.join(",\n") : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  min-width: 180px;
}

.form-control-plaintext {
  background: transparent;
  border: none;
  color: #000;
}

::v-deep .multiselect__select {
  z-index: 1;
}
</style>
